import React from "react"
import { Link, graphql } from "gatsby"
//import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

//import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  // const featuredImage = {
  //   fluid: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
  //   alt: post.featuredImage?.node?.alt || ``,
  // }
  //const image = !!featuredImage?.fluid? getImage(post.featuredImage.node.localFile.childImageSharp.gatsbyImageData) : null
  //console.log("image=", image, "featuredImage=", featuredImage)
  //console.log("post=",post)
  //const related_posts=post.related_posts;
  //console.log("post.content=",post.content)

  // const tweetThis = '<div className="tweetbox">' + post.title + '<a href="https://twitter.com/share?url=' + post.title.replaceAll(" ","-") + '&amp;text=' + post.title + ' @fbwh" title="Tweet this" target="_blank" rel="noreferrer">Tweet This</a></div>'
 
  // const fullurl = "https://www.findbestwebhosting.com/web-hosting-blog/index.php/" 
  //                 + post.title.replace(/ /g,"-")
   const fullurl = "https://www.findbestwebhosting.com/web-hosting-blog/index.php/" 
                  + post.uri
                  

  //const fullurl = location.href ? location.href : ''

  const tweetThis =  '<div class="tweetbox">' + post.title + '<a href="https://twitter.com/share?url=' + fullurl + '&amp;text=' + post.title + ' @fbwh" title="Tweet this" target="_blank" rel="noreferrer">Tweet This</a></div>'

  const postcontent = !!post.content? post.content.replace("</picture></noscript></span>", "</picture></noscript></span><br/><br/>"  + tweetThis + "<br/>" ) : ""

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <br/>
          <h1 itemProp="headline">{parse(post.title)}</h1>

          <p>{post.date}</p>

          {/* if we have a featured image for this post let's display it */}
          {/* {featuredImage?.fluid && (
            <GatsbyImage image={image} alt={featuredImage.alt} />
          )} */}

        </header>

        {!!postcontent && (
          <section itemProp="articleBody">{parse(postcontent)}</section>
        )}

        <hr />

{/*         <h3>Related Posts:</h3>
        <ul>
          {related_posts?.nodes?.map(({ slug, uri, title }) => (
            <li key={slug}>
              <Link to={uri} title={title} rel="bookmark">
                {title}
              </Link>
            </li>
          ))}
        </ul> */}

        <footer>
      {/*   <div class="tweetbox">Essential Guide To Converting An HTML Website To WordPress

        <a href="https://twitter.com/share?url=https%3A%2F%2Fwh615253.ispot.cc%2Ffbwh%2Fhtml-to-wordpress&amp;text=Essential Guide To Converting An HTML Website To WordPress @fbwh" title="Tweet this" target="_blank" >Tweet This</a>
        </div>


        <div className="tweetbox">7+ Proven Strategies To Increase Website Traffic (And Make More Sales)<a href="https://twitter.com/share?url=7+-Proven-Strategies-To-Increase-Website-Traffic-(And-Make-More-Sales)&amp;text=7+ Proven Strategies To Increase Website Traffic (And Make More Sales) @fbwh" title="Tweet this" target="_blank" >Tweet This</a></div> */}

        {parse(tweetThis)} 
 
       

        </footer>
      </article>



      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={"/web-hosting-blog/index.php" + previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li>
            {next && (
              <Link to={"/web-hosting-blog/index.php" + next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")

     #  related_posts {
      #     nodes {
        #       title
        #       slug
        #       uri
        #      }
        #    } 
# Cannot query field "sourceUrl" on type "WpNodeWithFeaturedImageToMediaItemConnectionEdge".
#         featuredImage {
#          node {
  #           altText
  #         localFile {
    #           childImageSharp {
      #           gatsbyImageData(
        #           quality: 90
        #         formats: [AUTO, WEBP, AVIF]
        #       )
        #       
        #     }
        #   }
        # }
        # } 
        }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
